<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Form Tambah Perintah">
      <b-row class="match-height">
        <b-col md="12">
          <validation-provider
            #default="{ errors }"
            name="Perihal"
            rules="required"
          >
            <b-form-group
              label="Perihal"
              label-for="Perihal"
            >
              <b-form-input
                id="Perihal"
                v-model="Perihal"
                placeholder="Tulis Perihal"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="Tanggal"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="startDate"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
            class="mb-2"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <b-form-select
                v-model="Kategori"
                :options="optionsKategori"
                placeholder="as"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-form-group
            label="File Disposisi"
            label-for="FileSurat"
          >
            <!-- Styled -->
            <b-form-file
              id="FileSurat"
              ref="file"
              v-model="file"
              type="file"
              placeholder="Tambah File Disposisi"
              drop-placeholder="Drop file here..."
              multiple="multiple"
              @change="fileChange"
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Deskripsi"
            class="mt-1 "
          >
            <label for="textarea-default">Deskripsi</label>
            <b-form-textarea
              id="textarea-default"
              v-model="Deskripsi"
              placeholder="Tulis Deskripsi"
              rows="3"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Deadline"
            rules="required"
          >
            <span
              v-show="startDate === null "
              class="warning"
            > Pilih Tanggal terlebih dahulu </span>
            <b-form-group
              label="Deadline"
              label-for="Deadline"
            >
              <b-form-datepicker
                id="deadline-datepicker"
                v-model="Deadline"
                :min="startDate"
                :disabled="startDate == null"
                locale="id"
                class="mb-1"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-form-group
            label="PIC"
            label-for="PIC"
            class="mb-2"
          >
            <v-select
              v-model="selectedKeputusan"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              placeholder="Pilih PIC"
              :options="optionsKeputusan"
            />
          </b-form-group>

          <b-form-group
            label="Divisi/ Bagian/Unit Terkait"
            label-for="selected"
            class="mb-2"
          > <span
              v-show="selectedKeputusan.length === 0"
              class="warning"
            > Pilih PIC terlebih dahulu </span>
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :disabled="selectedKeputusan.length === 0"
              multiple
              placeholder="Pilih Divisi"
              :close-on-select="false"
              :options="options"
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="FileSurat"
            rules="required"
          >
            <b-form-group
              label="Tag"
              label-for="tag"
            >
              <b-form-tags
                v-model="tags"
                input-id="tags-basic"
                placeholder="Tambah Tag"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <!-- <label for="textarea-default">Catatan</label>
          <b-form-textarea
            id="textarea-default"
            v-model="Catatan"
            placeholder="Tulis Catatan"
            rows="3"
          /> -->

          <!-- <b-form-group
            label="Private"
            label-for="private"
            class="mt-2"
          >
            <b-form-checkbox
              v-model="privates"
              checked="false"
              name="check-button"
              switch
              inline
            />
          </b-form-group> -->
        </b-col>
      </b-row>

      <b-button
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>
    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    flatPickr,
    BFormDatepicker,
  },
  data() {
    return {
      NoSurat: '',
      Deadline: null,
      startDate: null,
      Perihal: '',
      Kategori: '',
      Pengirim: '',
      Deskripsi: '',
      Catatan: '',
      file: [],
      files: [],
      privates: false,
      value: [],
      tags: [],
      sendData: [],
      userData: [],
      dir: 'ltr',
      errorLog: '',
      selectedKeputusan: [],
      optionsKategori: [
        { value: '', text: 'Pilih Kategori' },
        { value: 'Undangan', text: 'Undangan' },
        { value: 'Permohonan', text: 'Permohonan' },
        { value: 'CSR', text: 'CSR' },
        { value: 'Instansi Pendidikan', text: 'Instansi Pendidikan' },
        { value: 'Pemberitahuan', text: 'Pemberitahuan' },
        { value: 'Lain-lain', text: 'Lain-lain' },
      ],
      selected: [],
      options: [
        {
          item: '',
          name: '',
          userID: '',
          receiverType: '',
        },
      ],
      optionsKeputusan: [
        {
          value: '',
          text: '',
          userID: '',
          receiverType: '',
        },
      ],
      optionsViewers: [
        {
          value: '',
          text: '',
          userID: '',
          receiverType: '',
        },
      ],
    }
  },
  watch: {
    selectedKeputusan() {
      this.getRole()
      const index = this.selected
        .map(e => e.userID)
        .indexOf(this.selectedKeputusan.userID)
      this.selected.splice(index, 1)
    },
  },
  mounted() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.getDecision()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          feature: 'Assignment',
        },
        params: {
          feature: 'Assignment',
        },
      })
      this.files = data.data.map(val => ({
        real_filename: val.real_filename,
        new_filename: val.new_filename,
        ext: val.ext,
        date: val.date,
      }))
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addAssignment()
        }
      })
    },

    async addAssignment() {
      const responder = this.selected.map(e => ({
        user_id: e.userID,
        role_id: e.value,
        receiver_type: e.receiverType,
      }))
      await axios
        .post('siap/assignment/create', {
          feature: '',
          feature_ref_id: '',
          feature_ref_num: '',
          title: this.Perihal,
          cat: this.Kategori,
          desc: this.Deskripsi,
          start_date: dayjs(this.startDate).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          deadline: dayjs(this.Deadline).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          file: this.files,
          tags: this.tags,
          send_to: responder.concat({
            user_id: this.selectedKeputusan.userID,
            role_id: this.selectedKeputusan.value,
            receiver_type: this.selectedKeputusan.receiverType,
          }),
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          window.location.href = `/perintah/detail-perintah/${response.data.data.ref_num}`
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getRole() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_all: 'true',
        },
      })
      this.options = data.map(e => ({
        value: e.role.id,
        text: `${e.role.name} - ${e.user.name}`,
        userID: e.user.id,
        receiverType: 'Responder',
      }))
    },

    async getDecision() {
      const { data } = await axios.get('siap/account/receiver-type', {
        params: {
          feature: 'Disposition',
          show_by_code: 'A,B',
        },
      })
      this.optionsKeputusan = data.map(e => ({
        value: e.role.id,
        text: `${e.role.name} - ${e.user.name}`,
        userID: e.user.id,
        receiverType: 'PIC',
      }))
    },

    // async getViewer(val) {
    //   const { data } = await axios.get('siap/disposition/receiver-type', {
    //     filter_user_id: { hide_user_by_id: val },
    //     show_all: true,
    //   })
    //   this.optionsViewers = data.map(e => ({
    //     value: e.role.id,
    //     text: e.role.name,
    //     userID: e.user.id,
    //     receiverType: 'Guest',
    //   }))
    // },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.form-group {
  position: relative;
}
</style>

<style lang="scss" scoped>
.warning {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: 0;
  right: 15px;
  text-align: right;
}
</style>
