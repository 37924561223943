var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card-code',{attrs:{"title":"Form Tambah Perintah"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Perihal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perihal","label-for":"Perihal"}},[_c('b-form-input',{attrs:{"id":"Perihal","placeholder":"Tulis Perihal"},model:{value:(_vm.Perihal),callback:function ($$v) {_vm.Perihal=$$v},expression:"Perihal"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"Tanggal"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"date-datepicker","locale":"id"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Kategori","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kategori","label-for":"Kategori"}},[_c('b-form-select',{attrs:{"options":_vm.optionsKategori,"placeholder":"as"},model:{value:(_vm.Kategori),callback:function ($$v) {_vm.Kategori=$$v},expression:"Kategori"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":"File Disposisi","label-for":"FileSurat"}},[_c('b-form-file',{ref:"file",attrs:{"id":"FileSurat","type":"file","placeholder":"Tambah File Disposisi","drop-placeholder":"Drop file here...","multiple":"multiple"},on:{"change":_vm.fileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('validation-provider',{staticClass:"mt-1 ",attrs:{"name":"Deskripsi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Deskripsi")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Tulis Deskripsi","rows":"3"},model:{value:(_vm.Deskripsi),callback:function ($$v) {_vm.Deskripsi=$$v},expression:"Deskripsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Deadline","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.startDate === null ),expression:"startDate === null "}],staticClass:"warning"},[_vm._v(" Pilih Tanggal terlebih dahulu ")]),_c('b-form-group',{attrs:{"label":"Deadline","label-for":"Deadline"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"deadline-datepicker","min":_vm.startDate,"disabled":_vm.startDate == null,"locale":"id"},model:{value:(_vm.Deadline),callback:function ($$v) {_vm.Deadline=$$v},expression:"Deadline"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"PIC","label-for":"PIC"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","placeholder":"Pilih PIC","options":_vm.optionsKeputusan},model:{value:(_vm.selectedKeputusan),callback:function ($$v) {_vm.selectedKeputusan=$$v},expression:"selectedKeputusan"}})],1),_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Divisi/ Bagian/Unit Terkait","label-for":"selected"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedKeputusan.length === 0),expression:"selectedKeputusan.length === 0"}],staticClass:"warning"},[_vm._v(" Pilih PIC terlebih dahulu ")]),_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","disabled":_vm.selectedKeputusan.length === 0,"multiple":"","placeholder":"Pilih Divisi","close-on-select":false,"options":_vm.options},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('validation-provider',{attrs:{"name":"FileSurat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag"}},[_c('b-form-tags',{attrs:{"input-id":"tags-basic","placeholder":"Tambah Tag"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{staticClass:"bg-gradient-primary mt-2",attrs:{"variant":"outline-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Simpan")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }